<template>
    <div class="relative w-full h-full bg-black">
        <div
            :ref="`terminal-${label}`"
            class="absolute top-0 right-0 left-0 bottom-0 p-2 text-sm overflow-hidden">
        </div>
    </div>
</template>

<script>
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import debounce from 'lodash.debounce';

import { EventBus } from '@/utils';

let TERMINAL_REF = {};

export default {
    name: 'DeviceLogs',

    props: {
        logOutlet: {
            type: Array,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            terminalWaiting: true,
        };
    },

    watch: {
        logOutlet(newLogs) {
            if (newLogs.length > 0) {
                const newLine = newLogs[newLogs.length - 1];
                TERMINAL_REF.term.writeln(newLine);
            }
        },
    },

    mounted() {
        this.createTerminal();
        this.registerBusListeners();
    },

    beforeDestroy() {
        console.log('Got called!');
        this.destroyTerminal();
        this.cancelBusListeners();
    },

    methods: {
        async createTerminal() {
            const term = new Terminal({
                cursorBlink: true,
                cursorStyle: 'underline',
                disableStdin: true,
            });

            const fitAddon = new FitAddon();
            term.loadAddon(fitAddon);

            const terminalEl = this.$refs[`terminal-${this.label}`];
            term.open(terminalEl);
            await new Promise((resolve) => {
                setTimeout(() => resolve(), 250);
            });

            fitAddon.fit();

            TERMINAL_REF.term = term;
            TERMINAL_REF.fitAddon = fitAddon;

            if (this.logOutlet.length > 0) {
                this.logOutlet.forEach((line) => {
                    TERMINAL_REF.term.writeln(line);
                });
            }
        },

        resizeTerminal: debounce(() => {
            const { fitAddon } = TERMINAL_REF;
            if (fitAddon === undefined) return;
            fitAddon.fit();
        }, 500, { leading: true }),

        clearTerminal() {
            const { term } = TERMINAL_REF;
            if (term === undefined) return;
            term.clear();
        },

        destroyTerminal() {
            const { term, fitAddon } = TERMINAL_REF;
            if (term === undefined) return;
            fitAddon.dispose();
            term.dispose();
            TERMINAL_REF = {};
        },

        registerBusListeners() {
            EventBus.$on('resizeTerminal', this.resizeTerminal);
            window.addEventListener('resize', this.resizeTerminal);
            EventBus.$on('clearTerminal', this.clearTerminal);
        },

        cancelBusListeners() {
            EventBus.$off('resizeTerminal', this.resizeTerminal);
            window.removeEventListener('resize', this.resizeTerminal);
            EventBus.$off('clearTerminal', this.clearTerminal);
        },
    },
};
</script>
